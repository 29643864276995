/*
 * ============================
 * File: main.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Tuesday, 14th April 2020 7:17:10 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as Sentry from "@sentry/vue";

// Sentry
const environment = process.env.NODE_ENV;
if (environment === "production") {
  Sentry.init({
    Vue,
    dsn: "https://8b1e5d79d0c74e66d31659b4ab6ce124@o4505720607866880.ingest.sentry.io/4505720618090496",
    environment,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          "ticketme.app",
          process.env.VUE_APP_SERVER,
        ],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, hint) {
      const errorMessage = hint.originalException?.message || "";

      if (
        errorMessage.includes("Loading CSS chunk failed") ||
        errorMessage.includes("chunk failed")
      )
        return null;

      if (errorMessage.includes("Unexpected token '<'")) return null;

      return event;
    },
  });
}
import vuetify from "./plugins/vuetify";
import "./plugins";

import octaviaKit from "@/plugins/octavia.kit";

// confetti
import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);

// vuetify-mask
import "./plugins/vuetify-mask.js";

// Filters
import "./plugins/filters.js";
import "./plugins/mixin.js";

import "./registerServiceWorker.js";
//  Kit
Vue.use(octaviaKit);
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

// import { inject } from "@vercel/analytics";
// inject();
